<template>
  <span>
    <v-card class="mb-0 pb-1 mt-n4 parametros-v-card">
      <v-row justify="start" class="mx-auto mt-0">
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-select
            class="ml-8"
            :items="regionaisList"
            :value="regionalSelecionada"
            @change="setRegionalSelecionada"
            label="Regional selecionado"
          />
        </v-col>
        <v-col class="pb-0" cols="12" sm="6" md="4">
          <v-autocomplete
            class="ml-5"
            :items="listaMunicipios"
            v-model="municipioSelecionado"
            label="Município selecionado"
            clearable
          />
        </v-col>
      </v-row>
    </v-card>

    <v-row dense>
      <v-col cols="12">
        <v-card class="mt-8">
          <v-card-text class="pa-2">
            <v-simple-table class="data-table-slim" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th colspan="1"></th>
                    <th colspan="2">{{ competenciaDe | formatToMonth }}</th>
                    <th colspan="3"></th>
                    <th colspan="2">
                      Comparativo com a competência
                      {{ competenciaAte | formatToMonth }}
                    </th>
                  </tr>
                  <tr>
                    <th></th>
                    <th>Amostral</th>
                    <th>Eventual</th>
                    <th>Permanente</th>
                    <th>Total</th>
                    <th>-</th>
                    <th>Amostral</th>
                    <th>Eventual</th>
                    <th>Permanente</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><b>Total</b></td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_ams
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_eve
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_prm
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>

                    <td>-</td>

                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_ams,
                          dadosComparativo.comp_ate.total_ams
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_ams
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_eve,
                          dadosComparativo.comp_ate.total_eve
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_eve
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_prm,
                          dadosComparativo.comp_ate.total_prm
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_prm
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total,
                          dadosComparativo.comp_ate.total
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>

                  <tr>
                    <td><b>DRP e DRC violados</b></td>
                    <td>
                      {{
                        dadosComparativo.comp_de.amostrais_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.eventual_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.permanente_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>

                    <td>-</td>

                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.amostrais_nc,
                          dadosComparativo.comp_ate.amostrais_nc
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.amostrais_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.eventual_nc,
                          dadosComparativo.comp_ate.eventual_nc
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.eventual_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.permanente_nc,
                          dadosComparativo.comp_ate.permanente_nc
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.permanente_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_nc,
                          dadosComparativo.comp_ate.total_nc
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_nc ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>DRP e DRC não violados</b></td>
                    <td>
                      {{
                        dadosComparativo.comp_de.amostrais_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.eventual_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.permanente_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>

                    <td>-</td>

                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.amostrais_co,
                          dadosComparativo.comp_ate.amostrais_co
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.amostrais_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.eventual_co,
                          dadosComparativo.comp_ate.eventual_co
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.eventual_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.permanente_co,
                          dadosComparativo.comp_ate.permanente_co
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.permanente_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_co,
                          dadosComparativo.comp_ate.total_co
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_co ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>EVE regularizadas</b></td>
                    <td>-</td>
                    <td>
                      {{
                        dadosComparativo.comp_de.eve_regularizadas ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>-</td>
                    <td>
                      {{
                        dadosComparativo.comp_de.eve_regularizadas ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>

                    <td>-</td>

                    <td>-</td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.eve_regularizadas,
                          dadosComparativo.comp_ate.eve_regularizadas
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.eve_regularizadas ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                    <td>-</td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.eve_regularizadas,
                          dadosComparativo.comp_ate.eve_regularizadas
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.eve_regularizadas ||
                          0 | parseNumberToFloatBrIgnoringNaN
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>PLNA</b></td>
                    <td>
                      {{
                        dadosComparativo.comp_de.plna_ams
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.plna_eve
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.plna_prm
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td>
                      {{
                        dadosComparativo.comp_de.total_plna
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>

                    <td>-</td>

                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.plna_ams,
                          dadosComparativo.comp_ate.plna_ams
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.plna_ams
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.plna_eve,
                          dadosComparativo.comp_ate.plna_eve
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.plna_eve
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.plna_prm,
                          dadosComparativo.comp_ate.plna_prm
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.plna_prm
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                    <td
                      :class="
                        getClassComparativo(
                          dadosComparativo.comp_de.total_plna,
                          dadosComparativo.comp_ate.total_plna
                        )
                      "
                    >
                      {{
                        dadosComparativo.comp_ate.total_plna
                          | parseNumberToFloatBrIgnoringNaN
                      }}
                      %
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row no-gutters justify="center" class="mt-6">
      <h4>Regularizações das UCs</h4>
    </v-row>
    <v-row dense justify="center">
      <v-col cols="12">
        <v-data-table
          :loading="loadingItemsRegularizacaoUcs"
          :headers="headersTableRegularizacaoUcs"
          :items="itemsRegularizacaoUcs"
          class="elevation-3 data-table-slim pt-3"
          dense
          multi-sort
          :options="{
            itemsPerPage: 10,
          }"
          :headerProps="{
            sortByText: 'Ordenar Por',
          }"
          :footer-props="{
            showFirstLastPage: true,
            showCurrentPage: true,
            itemsPerPageOptions: [5, 15, 50, 100, -1],
          }"
          no-data-text="Nenhum registro disponível"
          no-results-text="Nenhum registro correspondente encontrado"
          :mobile-breakpoint="10"
        >
          <template v-slot:[`item.mes_referencia`]="{ item }">
            {{ item.mes_referencia | formatToMonth }}
          </template>
          <template v-slot:[`item.drp`]="{ item }">
            {{ item.drp | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.drc`]="{ item }">
            {{ item.drc | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template v-slot:[`item.compensacao`]="{ item }">
            {{ item.compensacao | parseNumberToFloatBrIgnoringNaN }}
          </template>
          <template
            v-slot:[`item.qtd_dias_submetidos_nt_inadequado`]="{ item }"
          >
            {{
              item.qtd_dias_submetidos_nt_inadequado
                | parseNumberToFloatBrIgnoringNaN
            }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </span>
</template>

<script>
  import DashboardNivelTensaoService from "@/services/DashboardNivelTensaoService";
  import MunicipiosService from "@/services/MunicipiosService";
  import { mapActions, mapState } from "vuex";

  export default {
    name: "DashboardNivelTensaoVisaoComparativo",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
    },
    props: {
      competenciaDe: {
        type: String,
        required: true,
      },
      competenciaAte: {
        type: String,
        required: true,
      },
      categories: {
        type: Array,
        required: true,
      },
      activatedTab: {
        type: Number,
        required: true,
      },
      traducaoIndicadores: {
        type: Object,
        default: {},
      },
      medicoesSelecionadas: {
        type: Array,
        required: true,
      },
      origemMedicoesSelecionadas: {
        type: Array,
        required: true,
      },
      tensaoReferenciaSelecionadas: {
        type: Array,
        required: true,
      },
      localizacaoUcSelecionadas: {
        type: Array,
        required: true,
      },
    },
    data: () => ({
      tipoVisao: "ucs",
      kpis: [""],
      regionais: [],
      listaMunicipios: [],
      dadosComparativo: [],
      headersTableRegularizacaoUcs: [
        { text: "UC", value: "uc" },
        { text: "Protocolo", value: "protocolo_atendimento" },
        { text: "Competência", value: "mes_referencia" },
        { text: "Tensão de Referênncia", value: "tensao_referencia" },
        { text: "DRP", value: "drp" },
        { text: "DRC", value: "drc" },
        { text: "Origem da Medição", value: "origem_medicao" },
        { text: "Resultado da Medicao", value: "resultado_medicao" },
        { text: "Compensação", value: "compensacao" },
        {
          text: "Quant. de dias submetido a nível de tensão inadequado",
          value: "qtd_dias_submetidos_nt_inadequado",
        },
      ],
      itemsRegularizacaoUcs: [],
      loadingItemsGroupedMunicipios: false,
      loadingItemsByMunicipio: false,
      loadingItemsRegularizacaoUcs: false,
    }),
    computed: {
      ...mapState('parametrosDashboardNivelTensao', [
        'regionalSelecionada',
        'municipio'
      ]),
      municipioSelecionado: {
        get() {
          return this.municipio;
        },
        set(newValue) {
          this.setMunicipio(newValue);
        }
      },
      regionaisList() {
        return this.regionais.map((el) => el.nome);
      },
      regionalSelecionadaId() {
        let regional = this.regionais.find(
          (element) => element.nome == this.regionalSelecionada
        );
        return regional == undefined ? 0 : regional.id;
      },
    },
    async mounted() {
      await this.getRegionais();
      this.getDados();
    },
    methods: {
      ...mapActions('parametrosDashboardNivelTensao', [
        'setRegionalSelecionada',
        'setMunicipio'
      ]),
      getRegionais() {
        return MunicipiosService.getRegionais()
          .then((res) => {
            this.regionais = res;
            if (this.regionalSelecionada == null) {
              this.setRegionalSelecionada(res.find(
                (el) => el.nome.toLowerCase() == "corporativa"
              ).nome);
            }
          })
          .catch((err) => {
            throw err;
          });
      },
      getDados() {
        // Apenas atualizado os dados se for a visao selecionada
        if (this.activatedTab !== 5) return;

        this.$emit("updateLoadingDados", true);

        Promise.all([
          this.getItemsRegularizacaoUcs(),
          this.getDadosTabelaComparativo(),
        ])
          .catch((err) => {
            console.error(err);
            this.$toast.error("Erro recuperar dados.", "", {
              position: "topRight",
            });
          })
          .finally(() => {
            this.$emit("updateLoadingDados", false);
          });
      },
      getDadosTabelaComparativo() {
        this.loadingItemsRegularizacaoUcs = true;
        this.ucSelecionada = [];

        return DashboardNivelTensaoService.getDadosTabelaComparativo(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.municipioSelecionado,
          this.tensaoReferenciaSelecionadas,
          this.localizacaoUcSelecionadas,
          this.medicoesSelecionadas,
          this.origemMedicoesSelecionadas
        )
          .then((res) => {
            this.dadosComparativo = res;
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsRegularizacaoUcs = false));
      },
      getItemsRegularizacaoUcs() {
        this.loadingItemsRegularizacaoUcs = true;
        this.ucSelecionada = [];

        return DashboardNivelTensaoService.getItemsRegularizacaoUcs(
          this.competenciaDe,
          this.competenciaAte,
          this.regionalSelecionadaId,
          this.municipioSelecionado,
          this.tensaoReferenciaSelecionadas,
          this.localizacaoUcSelecionadas,
          this.medicoesSelecionadas,
          this.origemMedicoesSelecionadas
        )
          .then((res) => {
            this.itemsRegularizacaoUcs = res;

            this.listaMunicipios = this.itemsRegularizacaoUcs
              .map((el) => el.nome_municipio)
              .sort((a, b) => Number(a ?? 0) - Number(b ?? 0));
          })
          .catch((err) => {
            throw err;
          })
          .finally(() => (this.loadingItemsRegularizacaoUcs = false));
      },
      getClassComparativo(valor_comp_de, valor_comp_ate) {
        if ((valor_comp_de || 0) - (valor_comp_ate || 0) >= 0) {
          return "success--text font-weight-bold";
        }

        return "error--text font-weight-bold";
      },
    },
    watch: {
      activatedTab() {
        this.getDados();
      },
      competenciaDe() {
        this.getDados();
      },
      competenciaAte() {
        this.getDados();
      },
      regionalSelecionada() {
        this.getDados();
      },
      municipioSelecionado() {
        this.getDados();
      },
    },
  };
</script>

<style>
  .parametros-v-card {
    box-shadow: 0px 0px 0px !important;
  }
  .data-table-slim table tbody tr td {
    font-size: 12px;
    padding: 0px 8px !important;
  }
  .data-table-slim table thead tr th {
    font-size: 12px;
    height: 25px;
    font-weight: 400;
  }

  .switch-tipo-visao .v-input__control {
    width: 32px !important;
  }
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__track,
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__thumb {
    color: #003a63 !important;
  }
</style>
